import React from "react";
import { Container, Button, Row, Col } from "reactstrap";

function WhoShouldAttend() {
  return (
    <div
      style={{
        backgroundColor: "transparent",
      }}
      className="section p-5"
      data-parallax={true}
    >
      <Container>
        <Row>
          <Col lg={4}>
            <h2 className="text-700 mb-2 border-bottom border-info text-white">Who Attends?</h2>
          </Col>
        </Row>
        <Row className="justify-content-start">
          <Col lg={12}>
            <h5 className="text-700 my-3 text-white">
              Meet and Network with C-Level’s, Senior Vice Presidents, Vice Presidents, Directors,
              and Heads of departments, etc of
            </h5>
          </Col>
        </Row>
        <Row className="my-4">
          {content.map((d) => (
            <Col lg={3} key={d} className="stat-div border p-3">
              <h5 className="text-400 m-2 text-white">{d}</h5>
            </Col>
          ))}
        </Row>
        <Row className="my-4">
          {wsaContent.map((d) => (
            <Col lg={3} key={d} className="stat-div border p-3">
              <h5 className="text-400 m-2 text-white">{d.text}</h5>
            </Col>
          ))}
        </Row>

        <br />
        <br />
        <Row className="justify-content-center">
          <Button className="p-2" color="primary" size="md" href="/register">
            <h5 className="m-0 text-400 text-white">Register Now</h5>
          </Button>
        </Row>
      </Container>
    </div>
  );
}
export default WhoShouldAttend;

const wsaContent = [
  { text: "Chief Executive Officer (CEOs)" },
  { text: "Chief Information Officers (CIOs)" },
  { text: "Chief Technology Officers (CTOs)" },
  { text: "Chief Information Security Officers (CISOs)" },
  { text: "Chief Innovation Officers (CINOs)" },
  { text: "Chief Risk Officers (CROs)" },
  { text: "Chief Operating Officers (COOs)" },
  { text: "Chief Marketing Officers (CMOs)" },
];

const content = [
  "Central Banks",
  "Commercial Banks",
  "Investment Banks",
  "Insurance Companies",
  "Islamic Bank",
  "Telco Operator",
  "FinTech’s and E- Commerce",
  "Payments Banks",
];
