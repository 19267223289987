import React from "react";
import { Container, Button, Row, Col, Card, CardBody } from "reactstrap";

function WhyAttend() {
  // const [isOpen, setIsOpen] = React.useState(false);

  // const toggle = i => setIsOpen(i);
  console.log(waContent);
  return (
    <div
      style={{
        backgroundColor: "transparent",
      }}
      className="section p-5"
      data-parallax={true}
    >
      <Container>
        <Row>
          <Col lg={12}>
            <h2 className="text-700 mb-4 text-white text-uppercase">Why Attend</h2>
            <h5 className="text-white text-400">
              Join us for an unparalleled opportunity to network and connect with over 150+ senior
              leaders from diverse industries. Our carefully curated agenda is designed to help you
              tackle your current business-critical challenges and drive your industry forward with
              innovative ideas and strategies. By attending our summit, you will gain access to
              valuable resources that are guaranteed to help you:
              <br />
              <br />
            </h5>
          </Col>
        </Row>
        <Row className="justify-content-center">
          {waContent.map((data, index) => (
            <Col lg={6} key={index} className="stat-div border">
              <div className=" p-3" style={{ minHeight: "130px" }}>
                <h5 className="text-700 mt-0 text-white">{data.title}</h5>
              </div>
            </Col>
          ))}
        </Row>
        <br />
        <br />
        <Row className="justify-content-center">
          <Button className="p-2 rounded-0" color="primary" size="lg" href="/register">
            <h5 className="m-0 text-400 text-white">Register Now</h5>
          </Button>
        </Row>
      </Container>
    </div>
  );
}
export default WhyAttend;

const waContent = [
  {
    title:
      "Forge new connections with like-minded senior leaders, expanding your professional network and opening doors to new opportunities.",
  },
  {
    title:
      "Build strong partnerships that will enable you to achieve your business objectives faster and more efficiently",
  },
  {
    title:
      "Stay current with emerging business trends and gain insights into the latest industry developments, ensuring yu remain at the forefront of your field.",
  },
  {
    title:
      "Download actionable takeaways that you can immediately apply to your business, helping you to optimize processes, improve efficiency, and boost profit.",
  },
  {
    title:
      "De-risk new projects by gaining a broad range of insights from experts in your industry, enabling you to make informed decisions and avoid costly mistaes.",
  },
  {
    title:
      "Understand the impact that new technologies can have on your business, and how you can leverage them to gain a competitive advantage",
  },
  // {
  //   title:
  //     "Network beyond the summit by scheduling one-on-one meetings and accelerating key projects through meaningful new partnerships",
  // },
  // {
  //   title:
  //     "Attending our summit is an investment in your professional development, and an opportunity to gain a competitive edge in your industry. Don't miss out on this chance to connect with top executives, learn from industry experts, and drive your business forward with confidence. Register today to secure your place!",
  // },
];
