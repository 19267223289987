import React from "react";
import { Container, Row, Col, Progress } from "reactstrap";
import CountUp from "react-countup";

// import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

function Stats() {
  let pageHeader = React.createRef();
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          backgroundColor: "#140d3e",

          backgroundPosition: "top right",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
        }}
        className="section py-3"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row className="text-white text-700 justify-content-center">
            <Col lg={12}></Col>
          </Row>
          {/* <Row style={{ marginTop: "1rem" }} className="justify-content-center">
            <Col lg={3} className="p-2">
              <h3
                className="text-900 text-center text-white mt-0 p-3"
                style={{ border: "5px solid #fff" }}
              >
                <CountUp end={500} duration={2.75} />+ Delegates
              </h3>
            </Col>
            <Col lg={3} className="p-2">
              <h3
                className="text-900 text-center text-white mt-0 p-3"
                style={{ border: "5px solid #fff" }}
              >
                <CountUp end={500} duration={2.75} />+ Delegates
              </h3>
            </Col>
            <Col lg={3} className="p-2 px-0">
              <h3
                className="text-900 text-center text-white mt-0 p-3"
                style={{ border: "5px solid #fff" }}
              >
                <CountUp end={500} duration={2.75} />+ Delegates
              </h3>
            </Col>
          </Row> */}

          {content1.map((c, i) => (
            <Row className="justify-content-center" key={i}>
              <Col lg={3} xs={12}>
                <h5 className="text-700 text-white">{c.title}</h5>
                <br />
              </Col>
              <Col lg={8} xs={12} key={c.title}>
                <Progress
                  striped
                  value={c.value}
                  animated
                  max="40"
                  style={{
                    height: "40px",
                    fontSize: "30px",
                    fontWeight: "700",
                    backgroundColor: "transparent",
                  }}
                  color="primary"
                >
                  {c.value}%
                </Progress>
              </Col>
            </Row>
          ))}
        </Container>
      </div>
    </>
  );
}

export default Stats;

const content1 = [
  { title: "C-Suites", value: 41.0 },
  { title: "VPs/Directors/Heads", value: 30.0 },
  { title: "Managers/ Senior Managers", value: 19.0 },
  { title: "Others", value: 10.0 },
];
