import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import ScrollAnimation from "react-animate-on-scroll";

function WhoSponsor() {
  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",
          // backgroundImage: `url(${svg})`,
          // backgroundPosition: "top right",
          // backgroundRepeat: "no-repeat",
          // backgroundSize: "100%",
        }}
        className="section py-0"
        data-parallax={true}
      >
        <Container>
          <Row>
            <Col lg={12} className="my-5">
              <h5 className="text-400 text-white mt-0">
                Looking to boost your business development pipeline for 2023 and beyond with
                high-quality leads? Look no further than our event, where your team can engage in
                one-on-one interactions with senior buyers in the financial services industry. These
                buyers are actively seeking to invest millions of dollars in new FinTech software in
                the next 12 months, making this an ideal opportunity for your team to establish
                valuable connections.
                <br />
                <br />
                Our event provides an environment where attendees are eager to discover innovative
                solutions to their problems, presenting a perfect opportunity for your team to
                showcase your expertise and demonstrate how your services can meet their needs.
                Don't miss out on this chance to generate leads and drive your business development
                efforts forward in 2023 and beyond.
              </h5>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col lg={12}>
              <h2 className="text-white mt-3 text-700" style={{ color: "#003972" }}>
                Top Reasons To Sponsor
              </h2>
            </Col>
            {content.map((s, i) => (
              <Col key={s.title} lg={12} xs={12} className="">
                <div className="container-fluid row">
                  <Col lg={6}>
                    <ScrollAnimation animateIn={"fadeInDown"} animateOnce={true} duration={1.5}>
                      <hr />
                      <h3 className="text-white text-700">{s.title}</h3>
                      <h5 className="text-white text-400 mt-2">{s.description}</h5>
                    </ScrollAnimation>
                  </Col>
                  <Col lg={6} className="mt-3">
                    <ScrollAnimation animateIn={"fadeInDown"} animateOnce={true} duration={1.5}>
                      <img
                        src={require(`assets/quotes/${i + 1}.jpg`)}
                        width="100%"
                        alt="main logo"
                      />
                    </ScrollAnimation>
                  </Col>
                </div>
              </Col>
            ))}
          </Row>
          <Row className="justify-content-center">
            <Col md={12} xs={12} className="mb-1 mt-3">
              <Container fluid>
                <Row>
                  <Col lg={4} xs={12} className={`px-1`}>
                    <Button className="px-3 py-2 my-5 rounded-0" color="primary" href="/register">
                      <p className="m-0 text-700" style={{ color: "#fff" }}>
                        Get a Sponsorship Kit
                      </p>
                    </Button>{" "}
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        style={{
          backgroundColor: "transparent",
        }}
        className="section py-0"
        data-parallax={true}
      >
        <Container>
          <Row>
            <Col lg={12} className="mt-5 text-center p-3 mb-4">
              <h5 className="text-400 mt-0 text-white p-3">
                Are you ready to take your brand to the next level? Exibex offers a game-changing
                opportunity to get your product or service in front of the most influential
                decision-makers and influencers.
                <br />
                <br />
                Our sponsorship opportunities are the key to unlocking new business opportunities
                and taking your brand to new heights. Don't miss out on this chance to make a
                significant impact on your target audience.
                <br />
                <br />
                Contact us today at{" "}
                <a href="mailto:info@exibex.com" className="text-primary">
                  <b>info@exibex.com</b>
                </a>{" "}
                or call us on{" "}
                <a href="tel:+971 585518437" className="text-primary">
                  <b>+971 585518437</b>
                </a>
                to learn more. Our team is ready to help you seize this opportunity and make a
                powerful impact in your industry. Let's take your brand to the top together!
              </h5>

              <br />
              <Container>
                <Row className="justify-content-center">
                  <Col lg={4}>
                    <Button
                      href="mailto:info@exibex.com"
                      className="btn my-2 text-center px-5"
                      color="primary"
                      size="lg"
                    >
                      Contact Us
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default WhoSponsor;

const content = [
  {
    title: "Opportunity for Link-Ups with Top Decision Makers in Attendance",
    description: `This event is set to host more than 150+  delegates, providing you with an exceptional platform to engage and build relationships with influential individuals who possess the authority to make impactful decisions. Seize this moment to network and influence those who hold the most significant power in the financial industry. `,
    image: "decision.png",
  },
  {
    title: "Target Your Audience with Precision: Connect with Key Industry Stakeholders",
    description: `With our focused and comprehensive delegate research, you can be confident that you'll have the chance to engage with the most relevant stakeholders. Join us to network with industry leaders and gain insights into their game-changing objectives!`,
    image: "competition.png",
  },
  {
    title:
      "Experience a comprehensive package of the latest financial marketing concepts, actionable insights, and networking opportunities with like-minded professionals.",
    description: `Our event unites a diverse range of specialists and experts in banking, digital transformation, and customer experience to explore, exchange, and showcase the most effective practices shaping the industry today. Join us to stay ahead of the curve and build valuable connections with your peers.`,
    image: "one.png",
  },
  {
    title: "Access Top-Tier Industry Knowledge at Finnovex",
    description: `At Finnovex, we curate the most exceptional experts and companies in the business to deliver you the very best insights and solutions. Our partners will showcase the latest cutting-edge products and services, providing you with a firsthand glimpse into the forefront of innovation.`,
    image: "showcase.png",
  },
  // {
  //   title: "Stay ahead of the competition in the rapidly-evolving banking landscape. ",
  //   description: `Join us to learn about the latest Fintech digital strategies that will transform your organisation's strategy and marketing for sustained growth. Gain a competitive edge and stand tall among your peers in the industry.`,
  //   image: "competition.png",
  // },
  // {
  //   title: "Leverage our Experience",
  //   description: `We offer you access to over 150,000 contacts accumulated from all 18+ growing  editions of the Finnovex Global Series - both online and offline marketing channels. Our extensive network of creative and inspired individuals is at your disposal to aid in the formation of your growth strategy. By partnering with us, you will benefit from our brand strategy, gaining a competitive edge in the market.`,
  //   image: "competition.png",
  // },
];
