import React from "react";
import { Container, Row, Col } from "reactstrap";
import Navbar from "components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import { sponsors } from "./content";

function SpeakerSingle(props) {
  const sponsorLink = props.match.params.sponsor;
  const sponsor = sponsors.find((x) => x.name === sponsorLink);

  return (
    <>
      <Navbar />
      <ProfilePageHeader title={sponsor.name} bgColor="#fff" />
      <div className="section profile-content">
        <Container>
          <div className="owner">
            <div
              className="avatar border mt-4"
              style={{
                backgroundColor: "#ffffff",
                maxWidth: "100%",
              }}
            >
              <a href={sponsor.link} target={"_blank"}>
                <img
                  alt={sponsor.name}
                  className="img-rounded img-no-padding img-responsive"
                  src={require(`assets/images/sponsors/${sponsor.image}`)}
                  style={{ maxWidth: "600px", width: "100%" }}
                />
              </a>
            </div>
            <div className="name">
              <h4 className="title" style={{ marginBottom: "0" }}>
                {sponsor.name}
              </h4>
              <br />
            </div>
          </div>
          <Row>
            <Col className="" md="12">
              <p dangerouslySetInnerHTML={{ __html: sponsor.text }}></p>
            </Col>
          </Row>
          <Row>
            <Col className="" md="12">
              <p>
                {" "}
                Learn More: <a href={sponsor.link}>{sponsor.link}</a>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
export default SpeakerSingle;
